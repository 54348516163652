import React, { FC } from 'react';
import { useSetRecoilState } from 'recoil';
import { SuggestionTypes, useGlobalSearchContext } from '../../../../store/global-search.context';
import { isOnSearchPage } from '../../../../helper/is-on-search-page';
import { useGlobalSearchValues, useSetGlobalSearch } from '../../../../store/recoil/global-search';
import { useRouter } from 'next/router';
import styles from './location-field.module.scss';
import { GlobalSearchInput } from '@digando/common-ui';
import { useTranslation } from 'next-i18next';
import {
  isSearchLocationOverlayOpenState
} from '../../../digando-overlay/search-location-overlay/states/search-location-overlay.states';
import { filterValueLocationSelector, manualRadiusSelector } from '../../../../store/recoil/product-search';
import {
  isGlobalSearchHeaderActiveState
} from './../global-search.state';

type LocationFIeldProps = {
  identifier: string;
  preselectedTenantKey: string | null;
  compact?: boolean;
}

export const LocationField: FC<LocationFIeldProps> = ({ identifier, preselectedTenantKey, compact }) => {
  const [t] = useTranslation(['search-component', 'common']);
  const { location } = useGlobalSearchValues();
  const { setLocation } = useSetGlobalSearch();
  const { openSuggestion, closeSuggestion } = useGlobalSearchContext();
  const router = useRouter();
  const setFilterValueLocation = useSetRecoilState(filterValueLocationSelector);
  const setIsSearchLocationOverlayOpen = useSetRecoilState(isSearchLocationOverlayOpenState);
  const setManualRadiusValue = useSetRecoilState(manualRadiusSelector);
  const setIsGlobalSearchHeaderActive = useSetRecoilState(isGlobalSearchHeaderActiveState);

  const onClearLocation = async (): Promise<void> => {
    // Reset search configuration
    setLocation({
      name: '',
      placeId: '',
    });
    setManualRadiusValue(null);

    // Reset search context, when we are already on search page
    // This will immediately update search results
    if (isOnSearchPage(router.pathname)) {
      setFilterValueLocation(null);
    }

    closeSuggestion();
  };

  if (preselectedTenantKey) {
    return null;
  }

  return (
    <div className={styles.container}>
      <GlobalSearchInput
        id={`global-search-location-${identifier}`}
        name={`global-search-location-${identifier}`}
        value={location?.name}
        defaultValue={location?.name}
        label={t('search-component:label-search-location')}
        placeholder={t('search-component:location-placeholder')}
        onChange={(value: string): void => {
          setLocation({
            name: value,
            placeId: '',
          })
        }}
        onFocus={(event): void => {
          setIsGlobalSearchHeaderActive(true);
          openSuggestion(SuggestionTypes.LOCATION);
          setIsSearchLocationOverlayOpen(true);

          event.target.select();
        }}
        onDelete={onClearLocation}
        compact={compact}
      />
    </div>
  );
};
